










import {Vue, Component, Emit, Watch} from "vue-property-decorator";
import ApplyFriendContType from "./indexType";

@Component({name: "ApplyFriendCont"})
export default class ApplyFriendCont extends Vue implements ApplyFriendContType{
    value = ""

    @Watch("value")
    @Emit("pullAndChangeValue")
    pullAndChangeValue(newVal:string){
        return newVal
    }
}

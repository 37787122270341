

















import {Vue, Component} from "vue-property-decorator";
import ApplyFriendType from "./ApplyFriend";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import ApplyFriendHead from "@/views/Friend/ApplyFriend/components/Head/index.vue"
import ApplyFriendCont from "@/views/Friend/ApplyFriend/components/Cont/index.vue"
import { IssueFriendApply } from './Server';
import { Dialog } from 'vant';

@Component({name: "ApplyFriend",components:{ HeadTop,ApplyFriendHead,ApplyFriendCont }})
export default class ApplyFriend extends ZoomPage implements ApplyFriendType{
    value = ""

    mounted(){
        this.loadingShow = false
    }

    handleGetValue(value:string){
        this.value = value
    }

    handleClickBtn(){
        if ( this.value.length < 1 ){
            Dialog.confirm({
                title: '标题',
                message: '弹窗内容',
            }).then(res=>{
                this.handleApplyFriend()
            }).catch(cat=>cat)
        }else{
            this.handleApplyFriend()
        }
    }

    handleApplyFriend(){
        let { data = "{}" } = this.$route.query
        let JsonData = JSON.parse(data as string)
        let { id = "" } = JsonData
        IssueFriendApply(id,this.value).then(res=>{
            this.$router.go(-2)
        })
    }
}

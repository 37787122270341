


























import {Vue, Component} from "vue-property-decorator";
import ApplyFriendHeadType from "./indexType";
import moment from "moment";

@Component({name: "ApplyFriendHead"})
export default class ApplyFriendHead extends Vue implements ApplyFriendHeadType{
    data:any = {}

    activated(){
        let { data = "" } = this.$route.query
        this.data = JSON.parse(data as string)
        console.log( this.data )
    }

    get getPhoto(){
        return this.data && this.data.headImg || require("@/assets/icon/Basics/default.png")
    }

    get getSex(){
        return this.data && this.data.sex && Number(this.data.sex) && require("@/assets/icon/Basics/sex.png") || require("@/assets/icon/Basics/sex1.png")
    }

    get getAge(){
        let now = moment(),
            birth = moment(this.data.birthday);
        return now.diff(birth,"years")
    }
}
